<template>
  <div class="kt-loading">
    <div class="kt-loading kt-portlet__body kt-portlet__body--fit">
      <div class="spinner-border" role="status">
        <span class="sr-only">Загрузка...</span>
      </div>
    </div>
  </div>
</template>

<style lang="sass">
.kt-loading
  padding: 25px 0
  height: 100%
  width: 100%
  color: var(--bs-dark)
  display: flex
  align-items: center
  justify-content: center
</style>