import Vue from 'vue'
import Vuex from 'vuex'
import {showProfile} from "@/services/customer/profile/profile";

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    globalLoading: false,
    token: localStorage.getItem('customerToken') || '',
    email: localStorage.getItem('customerEmail') || '',
    userData: {},
    curLanguage: {
      locale: localStorage.getItem('curLanguageLocale') == null ? 'ru_ru' : localStorage.getItem('curLanguageLocale'),
    },
    layout: 'default-layout',
		theme: localStorage.getItem('LMSTheme') || 'light',
    credentials: {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('customerToken'),
        'Accept-Language': getHeaderLanguage(localStorage.getItem('curLanguageLocale') === null ? 'ru' : localStorage.getItem('curLanguageLocale'))
      }
    },
    url: 'https://a.lmska.com',
    nonCashPayment: {
      fioCompany: '',
      edrpou: '',
      bankAccount: '',
      addressPhone: ''
    },
    interval: null
  },
  mutations: {
    setLanguage(state, data) {
      state.curLanguage.locale = data.lang;
      state.credentials.headers['Accept-Language'] = getHeaderLanguage(data.lang)
      localStorage.setItem('curLanguageLocale', state.curLanguage.locale);
    },
    setUserData(state, data) {
      state.userData = data
    },
    login(state, data) {
      state.interval = setInterval(() => {
        showProfile().then(() => {}).catch(() => {})
      },  30000)

      if (data.token) {
        localStorage.setItem('customerToken', data.token)
        state.token = data.token ? data.token : localStorage.getItem('customerToken')
        state.credentials.headers['Authorization'] = 'Bearer ' + data.token
      }
      localStorage.setItem('customerEmail', data.email)
      state.email = data.email

      state.userData = data
    },
    resetUserData: state => {
      clearInterval(state.interval)
      Object.assign(state, { token: '', email: '' })
      localStorage.removeItem('customerToken')
      localStorage.removeItem('customerEmail')
    },
    setLayout(state, payload) {
      state.layout = payload
    },
    setNonCashPaymentInfo: (state, payload) => {
      state.nonCashPayment = payload
			localStorage.setItem('nonCashPaymentInfo', JSON.stringify(payload))
    },
    setGlobalLoading: (state, payload) => {
      state.globalLoading = payload
    },
		setTheme: (state, payload) => {
			state.theme = payload
			localStorage.setItem('LMSTheme', payload)
			document.documentElement.classList.remove('theme--light')
			document.documentElement.classList.remove('theme--dark')
			document.documentElement.classList.add('theme--' + payload)
		}
  },
  actions: {
    login: (context, data) => {
      context.commit('login', data)
    },
    logout: (context) => {
      context.commit('resetUserData')
    },
    setUserData(context, response) {
      context.commit('setUserData', response)
    },
    languageChange: (context, data) => {
      context.commit('setLanguage', data)
    },
    changeNonCashInfo: (context, data) => {
      context.commit('setNonCashPaymentInfo', data)
    },
    setGlobalLoading: (context, data) => {
      context.commit('setGlobalLoading', data)
    },
		setTheme: (context, data) => {
			context.commit('setTheme', data)
		}
  },
  getters: {
    isLoggedIn: state => {
      return state.token
    },
    getUserEmail: state => {
      return state.email
    },
    getUserData: state => {
      return state.userData
    },
    curLanguage(state) {
      return state.curLanguage
    },
    layout(state) {
      return state.layout
    },
    nonCashPaymentInfo(state) {
      return state.nonCashPayment
    },
    url(state) {
      return state.url
    },
    globalLoading(state) {
      return state.globalLoading
    }
  }
})

function getHeaderLanguage(lang) {
  if(lang === 'ru_ru') {
    return 'ru'
  } else if(lang === 'en_us') {
    return 'en'
  } else if(lang === 'uk_ua') {
    return 'ua'
  }
  return 'ru'
}