<template>
  <div class="kt-notification" :class="{'show': showNotificationModal}" v-click-outside="onClickOutside">
  
    <div class="kt-notification__selector" data-toggle="dropdown" @click="showNotificationModal = !showNotificationModal">
      <div class="kt-notification__icon kt-header__topbar-icon">
        <i class="bx bx-bell" :class="{'bx-tada': unreadCount > 0}"></i>
        <span class="kt-notification__icon--unread" v-if="unreadCount > 0">{{unreadCount}}</span>
      </div>
    </div>
  
    <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl user-bar-modal"
         :class="{'show': showNotificationModal}">
      <!--begin: Head -->
      <div class="kt-notification__header kt-user-card--skin-dark kt-notification-item-padding-x">
        <h3 class="kt-notification__title">{{$t('notification.title')}}</h3>
        <a class="kt-notification__link" href="#" @click.prevent="markAllAsRead" v-if="!notifications.length">
          <b-spinner small variant="light" v-if="markLoading"></b-spinner>
          {{$t('notification.markAll')}}
        </a>
      </div>
      <!--end: Head -->
    
      <!--begin: Navigation -->
      <div class="kt-notification__body" v-if="!loading">
        <ul class="kt-notification__list" v-if="notifications.length">
          <li class="kt-notification__item"
              @click="notifAction(notification)"
              :class="{'not-viewed': notification.is_viewed === 0, 'no-icon': !notification.href}"
              v-for="notification in notifications"
              :key="notification.id">
            <div class="kt-notification__item-icon">
              <i class="flaticon2-telegram-logo" v-if="notification.type === 'telegram'"></i>
              <i class="flaticon2-list-3" v-else></i>
            </div>
            <div class="kt-notification__item-details">
              <h4 class="kt-notification__item-title fw-500" v-html="notification.message"></h4>
              <div class="kt-notification__item-small mb-2" v-if="notification.course">
                <span class="kt-badge kt-badge--label-brand kt-badge--inline ml-0">{{notification.course.title}}</span>
              </div>
              <div class="kt-notification__item-time">{{formatDate(notification.notificated_at)}}</div>
            </div>
          </li>
        </ul>
        <div class="py-5" v-else>
          <div class="public__empty-image mb-5">
            <img src="/assets/media/calm.png" alt="No unread">
          </div>
          <h4 class="text-center m-0">{{$t('notification.empty')}}</h4>
        </div>
      </div>
      <Loading v-else />
      <!--end: Navigation -->
  
      <!--begin: Footer -->
      <div class="kt-notification__footer">
        <router-link to="/user-profile?tab=4" class="kt-notification__link kt-font-brand">
          {{$t('notification.history')}}
        </router-link>
      </div>
      <!--end: Footer -->
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
import {getNotificationCount, getNotifications, setNotificationRead} from "@/api/customer/notification.js";
export default {
  name: "Notification",
  components: {
    Loading: () => import('@/components/general/Loading.vue')
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      loading: false,
      markLoading: false,
      unreadCount: 0,
      showNotificationModal: false,
      notifications: []
    }
  },
  computed: {
    curLang() {
      return this.$store.getters.curLanguage
    },
    isLogin() {
      return this.$store.getters.isLoggedIn
    }
  },
  created() {
    if (this.isLogin) {
      this.loadCount()
  
      this.$bus.$on('loadNotificationCount', () => {
        this.loadCount()
      })
    }
  },
  methods: {
    loadCount() {
      getNotificationCount().then(res => {
        this.unreadCount = res.data
      })
    },
    onClickOutside () {
      this.showNotificationModal = false
    },
    formatDate(date) {
      if (!date || typeof date !== 'string') return ''
      const lang = this.getLocaleISO(this.curLang.locale)
      return this.$moment(new Date(date)).locale(lang).fromNow();
    },
    getLocaleISO(local) {
      if (local === 'en_us') return 'en'
      if (local === 'ru_ru') return 'ru'
      return 'uk'
    },
    markAllAsRead() {
      this.markLoading = true
      const meta = {
        ids: this.notifications.map(n => n.id)
      }
      setNotificationRead(meta).then(() => {
        this.unreadCount = 0
        this.notifications.forEach(n => n.is_viewed = 1)
      }).finally(() => {
        this.markLoading = false
      })
    },
    notifAction(item) {
      if (item.href) {
        const meta = {ids: [item.id]}
        setNotificationRead(meta).then(() => {
          this.unreadCount--
        })
        this.showNotificationModal = false
        this.$router.push(item.href)
      }
    }
  },
  watch: {
    showNotificationModal(bool) {
      if (bool) {
        this.loading = true
        const params = {all: 0}
        getNotifications(params).then(res => {
          this.notifications = res.data.data.filter(n => n.message)
          this.notifications.forEach(n => {
            let messageArr = n.message.replaceAll(/\n/g, '<br>').split('<a href="')
            n.message = messageArr[0]
            if (messageArr[1]) {
              n.href = messageArr[1].split('"')[0].replace('https://c.lmska.com/#', '')
            }
          })
        }).finally(() => {
          this.loading = false
        })
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.public__empty-image
  max-width: 100%
  width: 100px
  margin: 0 auto
  img
    width: 100%
    height: auto
  & + h4
    font-size: 1.2rem
    padding: 0 2rem
    margin: 0
    color: var(--bs-dark)
.kt-badge--label-brand
  background: rgba(93,120,255,.1)
  color: #5d78ff
  font-weight: 500
.fw-500
  font-weight: 500 !important
.kt-notification
  position: relative
  //padding: 0 0.75rem
  height: 100%
  display: flex
  align-items: center
.kt-notification__icon
  display: flex
  align-items: center
  justify-content: center
  position: relative
  cursor: pointer
  i
    font-size: 24px
    line-height: 1.2
  &--unread
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    top: 0
    right: 0
    background: #fd397a
    color: #fff
    border-radius: 50%
    width: 15px
    height: 15px
    font-size: 10px
    font-weight: 500
.kt-notification__header
  display: flex
  align-items: center
  justify-content: space-between
  color: var(--bs-dark)
  font-weight: 700
  padding: 1rem
.kt-notification__title
  margin: 0
  font-size: 1rem
.kt-notification__link
  color: #fff
  text-decoration: underline
  font-size: 12px
  line-height: 1.2
.kt-notification__list
  margin: 0
  padding: 0
  overflow-y: auto
  height: 330px
.kt-notification
  .kt-notification__item
    cursor: pointer
    border-color: var(--bs-light)
    &.not-viewed
      background: rgba(93,120,255,.1)
    &.no-icon
      &:after
        display: none
.kt-notification__item-icon
  line-height: 1
.kt-notification__item-small
  font-size: 0.8rem
  display: flex
  span:not(.label)
    margin-left: 5px
.kt-notification__footer
  display: flex
  align-items: center
  justify-content: center
  padding: 1rem
  font-weight: 700
  text-align: center
  border-top: 1px solid var(--bs-light)
  position: relative
  z-index: 1
</style>