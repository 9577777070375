<template>
    <!--begin: User Bar -->
    <div class="kt-header__topbar-item kt-header__topbar-item--user position-relative"
         :class="{'show': showUserModal}"
         v-click-outside="onClickOutside">
        <div class="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="0px,0px" v-on:click="showUserModal = !showUserModal">
          <div class="kt-header__topbar-user">
            <span class="kt-header__user-avatar" v-if="userData.avatar">
              <img :src="userData.avatar" alt="User photo">
            </span>
            <span class="kt-header__topbar-username kt-hidden-mobile">
              {{userData.email == null ? "Guest" : userData.email}}
            </span>
            <svg class="ml-2" fill="#fff" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="8px" height="8px" viewBox="0 0 255 255" xml:space="preserve">
              <g id="arrow-drop-down"><polygon data-v-5f71f4ba="" points="0,63.75 127.5,191.25 255,63.75"></polygon></g>
            </svg>
          </div>
        </div>

        <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl user-bar-modal"
             :class="{'show': showUserModal}">
            <!--begin: Head -->
          <div class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x">
              <div class="kt-user-card__avatar" style="min-width: 60px;" v-if="Object.keys(userData).length">
                  <b-avatar size="60px" :src="userData.avatar" square v-if="userData.avatar"></b-avatar>
<!--                  <img :src="userData.avatar" alt="User Photo" v-if="userData.avatar">-->
                  <span class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success" v-else>
                    <template v-if="userData.first_name">{{userData.first_name.charAt(0)}}</template>
                    <template v-else>{{userData.email.charAt(0)}}</template>
                  </span>
              </div>
              <div class="kt-user-card__name">
                <template v-if="Object.keys(userData).length">
                  <template v-if="userData.first_name">{{userData.first_name}} {{userData.last_name}}</template>
                  <template v-else>{{userData.email}}</template>
                </template>
                <template v-else>{{$t('error.errorData')}}</template>
              </div>
          </div>
          <!--end: Head -->

          <!--begin: Navigation -->
          <div class="kt-notification">
            <router-link to="/user-profile" class="kt-notification__item">
              <div class="kt-notification__item-icon">
                  <i class="fa fa-user-o kt-font-success"></i>
              </div>
              <div class="kt-notification__item-details">
                <div class="kt-notification__item-title kt-font-bold">
                  {{$t('menu.profile')}}
                </div>
                <div class="kt-notification__item-time">
                  {{$t('menu.settings')}}
                </div>
              </div>
            </router-link>
            <router-link to="/user-public" class="kt-notification__item">
              <div class="kt-notification__item-icon">
                <i class="fa fa-users kt-font-success"></i>
              </div>
              <div class="kt-notification__item-details">
                <div class="kt-notification__item-title kt-font-bold">
                  {{$t('menu.publicProfile')}}
                </div>
                <div class="kt-notification__item-time">
                  {{$t('menu.publicSettings')}}
                </div>
              </div>
            </router-link>
              <div class="kt-notification__custom kt-space-between">
                <button @click="$emit('logout')"
                     class="btn btn-label btn-label-brand btn-sm btn-bold">
                  {{$t('menu.logout')}}
                </button>
              </div>
          </div>
            <!--end: Navigation -->
        </div>
    </div>
</template>

<script>
import vClickOutside from 'v-click-outside'

export default {
  name: 'UserBar',
  props: ['userData'],
  directives: {
    clickOutside: vClickOutside.directive
  },
  data () {
    return {
      showUserModal: false
    }
  },
  computed: {
    user() {
      return this.$store.state.userData;
    },
    userId() {
      return localStorage.getItem('userId')
    },
    userRole() {
      return localStorage.getItem('userRoleRTE')
    }
  },
  methods: {
    onClickOutside () {
      this.showUserModal = false;
    },
  }
}
</script>

<style lang="sass">
.kt-header .kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user .kt-header__topbar-username
  color: var(--bs-white)
.kt-header__topbar-wrapper
  cursor: pointer
  font-weight: 500
.kt-user-card
  right: 0
  background-color: #5d78ff
.user-bar-modal
  left: auto
  right: 0
.kt-header__topbar-user
  display: flex
  align-items: center
.kt-header__user-avatar
  display: flex
  width: 30px
  height: 30px
  margin-right: 10px
  overflow: hidden
  border-radius: 50%
  img
    width: 100%
    height: 100%
    object-fit: cover
.kt-user-card__avatar
  height: 60px
  width: 60px
  position: relative
  overflow: hidden
.btn.btn-label-brand
  color: #5d78ff
  &:hover
    color: #fff !important
.kt-notification
  .kt-notification__item,
  .kt-notification__custom
    border-color: var(--bs-light)
    .kt-notification__item-details
      .kt-notification__item-title
        color: var(--bs-dark)
      .kt-notification__item-time
        color: var(--bs-gray)
  .kt-notification__item:hover
    background: var(--bs-light)
</style>