<template>
  <div class="empty-wrapper">
    <NavBar short="true"/>
    <main class="main">
      <slot />
    </main>
  </div>
</template>

<script>
import NavBar from "@/components/general/NavBar";

export default {
  name: 'DefaultLayout',
  components: {
    NavBar
  },
  beforeMount () {
    this.$i18n.locale = this.$store.state.curLanguage.locale;
  },
}
</script>

<style lang="scss" scoped>
body {
  height: 100%;
  background: #fff;
}
.empty-wrapper {
  height: 100%;
}
</style>