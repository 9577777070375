<template>
  <div class="default-layout">
    <NavBar />
    <main class="main">
      <slot v-if="!loading" />
    </main>
    <footer class="footer">
      <div class="container">
        <div class="footer__wrap">
          <p class="m-0 text-center">Copyright 2023. All rights reserved.</p>
          <a href="https://t.me/RTE_LMSka" target="_blank">{{$t('general.platform')}}</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import NavBar from "@/components/general/NavBar";
import {showProfile} from "@/services/customer/profile/profile";

export default {
  name: 'DefaultLayout',
  components: {
    NavBar
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    token() {
      return this.$store.getters.isLoggedIn
    }
  },
  beforeMount () {
    this.$i18n.locale = this.$store.state.curLanguage.locale;
  },
  created() {
    if (this.token) {
      this.loading = true
      showProfile().then(response => {
        this.$store.dispatch('login', response)
      }).finally(() => {this.loading = false}).catch(error => {
        this.$swal(this.$t('error.error'), error.data.message, 'error')
      })
    }
  }
}
</script>

<style lang="scss">
body.swal2-shown.swal2-height-auto {
  height: 100% !important;
}
.default-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 55px;
}
.main {
  position: relative;
  flex-grow: 1;
}
.footer {
  background: #f2f2f5;
  color: #74788d;
  font-weight: 500;
  margin-top: 50px;
  font-size: 0.9rem;
  .theme--dark & {
    background: #262b3c;
    color: #a6b0cf;
    .footer__wrap {
      a {
        color: #a6b0cf;
      }
    }
  }
}
.footer__wrap {
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    display: inline-block;
    color: #74788d;
    text-decoration: underline;
    transition: all .2s;
    &:hover {
      text-decoration: none;
    }
  }
}
@media screen and (max-width: 768px) {
  .default-layout {
    padding-top: 110px;
  }
}
</style>