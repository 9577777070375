import axios from 'axios'
import store from '@/store/'
import errorResponseHandler from "@/api/responseError/errorResponseHandler";

const backendApi = 'https://a.lmska.com'

export function getNotifications(params) {
	return new Promise((resolve, reject) => {
		axios.get(`${backendApi}/api/notifications/alerts`, {
			headers: store.state.credentials.headers,
			params: params
		})
		.then(response => {
			resolve(response)
		})
		.catch(error => {
			reject(errorResponseHandler(error.response))
		})
	})
}

export function getNotificationCount() {
	return new Promise((resolve, reject) => {
		axios.get(`${backendApi}/api/notifications/alert-count`, store.state.credentials)
		.then(response => {
			resolve(response)
		})
		.catch(error => {
			reject(errorResponseHandler(error.response))
		})
	})
}

export function setNotificationRead(meta) {
	return new Promise((resolve, reject) => {
		axios.put(`${backendApi}/api/notifications/alert-read`, meta, store.state.credentials)
		.then(response => {
			resolve(response)
		})
		.catch(error => {
			reject(errorResponseHandler(error.response))
		})
	})
}